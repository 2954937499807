.trendings h2{
    margin-block-end: 1rem;
}
.trendings a{
    color: yellow;
    text-decoration: none;
    margin-inline-end: 1rem;
}
.trendings a:hover{
    text-decoration: underline;
}