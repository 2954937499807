.container{
    padding: 1rem;
}
body{
    font-family: Roboto, sans-serif;
    background-color: var(--cgt-red);
    text-align: center;
}
h2{
    color: var(--yellow);
}

@media  screen and (min-width: 768px){
    .container{
        max-width: 768px;
        text-align: left;
        margin:auto;
    }
}

@media  screen and (min-width: 992px){
    .container{
        max-width: 992px;
    }
}

@media  screen and (min-width: 1200px){
    .container{
        max-width: 1200px;
    }
}

@media  screen and (min-width: 1400px){
    .container{
        max-width: 1400px;
    }
}