.touit{
    display: flex;
    flex-direction: column;
    margin-block-end: 1rem;
    padding-block: 1rem;
    padding-inline: .5rem;
    border-radius: 1rem;
    word-break: break-all;
    overflow: hidden;
    background-color: var(--cgt-red);
    color: var(--yellow);
    outline: 1px solid var(--yellow);
}
.touit:hover{
    color: var(--cgt-red);
    background-color: var(--yellow);
}
.touit p{
    margin-inline-end: 1rem;
}
.touit-pseudo{
    font-style: italic;
}
.touit-left{
    align-items: flex-start;
}
.touit-right{
    align-items: flex-end;
}