/* https://piccalil.li/blog/a-modern-css-reset */

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove focus for mouse users */
/* TODO : améliorer pour les éléments de formulaire */
:focus:not(:focus-visible) {
    outline: none;
}

/* Prevent font size inflation */
html {
    -moz-text-size-adjust: none;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
}

/* Remove default margin */
:is(body, h1, h2, h3, h4, p, figure, blockquote, dl, dd) {
  margin: 0;
}

/* 2023-01-18 : Modified for CEFIM
Remove list styles, margin and padding on ul, ol elements with a class, which suggests default styling will be removed */
:is(ul, ol):where([class]) {
	margin: 0;
	padding: 0;
	list-style: none;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}

/* Set shorter line heights on headings and interactive elements */
:is(h1, h2, h3, h4,button, input, label) {
  line-height: 1.1;
}

/* Balance text wrapping on headings */
:is(h1, h2,h3, h4) {
  text-wrap: balance;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Anything that has been anchored to should scroll and have extra scroll margin */
:has(:target) {
    scroll-behavior: smooth;
    scroll-margin-block: 2.5em;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}