.influencing h2{
    margin-block-end: 1rem;
}
.influencing a{
    color: yellow;
    text-decoration: none;
    margin-inline-end: 1rem;
}
.influencing a:hover{
    text-decoration: underline;
}
.influencing{
    word-break: break-all;
    overflow: hidden;
}