.send-message-form{
    display: grid;
    justify-items: center;
    gap: .5rem;
    color: var(--yellow);   
}
.pseudo-form{
    width: 16ch;
    height: 2em;
}
.message-form{
    width: 100%;
    height: 8em;
}
.send-message-form input[type="submit"]{
    width: 16ch;
    background-color: var(--cgt-red);
    color: var(--yellow);
    border: none;
    padding-inline: 0;
}
.send-message-form input[type="submit"]:hover{
    cursor: pointer;
    text-decoration: underline;
}
.send-message-form input[type="submit"]:disabled{
    cursor: not-allowed;
    text-decoration: none;
}

@media  screen and (min-width: 768px){
    .send-message-form{
        justify-items: start;
    }
    .message-form{
        max-width: 128ch;
        height: 4em;
    }
    .send-message-form input[type="submit"]{
        text-align: start;
    }
}

@media  screen and (min-width: 992px){
    .message-form{
        max-width: 128ch;
        height: 4em;
    }
}

@media  screen and (min-width: 1200px){
    .message-form{
        width: 128ch;
        height: 4em;
    }
}

@media  screen and (min-width: 1400px){
    .message-form{
        width: 128ch;
        height: 4em;
    }
}